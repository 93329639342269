<template>
  <div>
    <p class="font-sansDemi text-base text-white">
      Why do you think {{ item.originalChannelName }} is harmful? Please provide keywords, you can add more than one label.
    </p>
    
    <div class="flex flex-wrap mt-3">
      <p
        v-for="item in keywordsList"
        :key="item.id"
        class="flex items-center base-bg-darker-190 py-1.5 px-4 m-2 font-monoDemi text-sm text-white border border-grey-light-170 rounded-2xl "
      >
        <span class="mr-3">{{ item.title }}</span>
        <closeIcon 
          class="cursor-pointer"
          @close="removeKeyword(item.id)"
        />
      </p>
    </div>
    <vue-autosuggest
      :suggestions="sortedLabels"
      :input-props="{id:'autosuggest__input', placeholder:'Type a keyword'}"
      :sectionConfigs="sectionConfigs"
      :getSuggestionValue="getSuggestionValue"
      @input="filterResults"
      @selected="onSelected"
    >  
      <template slot-scope="{suggestion}">
        <div class="my-suggestion-wrap">
          <p class="my-suggestion-item">{{ suggestion.item.title }}</p>
          <p class="my-suggestion-description">{{ suggestion.item.description }}</p>
        </div>
      </template>
    </vue-autosuggest>
    <button 
      @click="label"
      class="bg-white rounded-2xl px-4 py-1.5 font-monoDemi text-sm text-black mt-16 mr-3"
      :class="[{
        'disabled:opacity-50': !keywordsList.length
      }]"
      :disabled="!keywordsList.length"
    >
      Submit
    </button>
    <button 
      @click="goToForm"
      class="bg-white rounded-2xl px-4 py-1.5 font-monoDemi text-sm text-black mt-16"
    >
      Keyword is not in list
    </button>
  </div>
</template>

<script>
import labels from './labels.json';
import { VueAutosuggest } from 'vue-autosuggest';
import { mapGetters } from 'vuex'
import closeIcon from '@/components/icons/close-icon'

  export default {
    props: [ 'item', 'preSelected' ],
    components: { closeIcon, VueAutosuggest },
    data(){
      return{
        keywordsList: [],
        labels: labels,
        sortedLabels: [],
        sortedLabelsBase: [],
        sectionConfigs: {},
        selected: ''
      }
    },
    methods: {
      goToForm(){
        window.open(`https://wysbvmx6kmr.typeform.com/to/KHbI0RsI#name=${this.user_meta.id}`, '_blank')
      },
      addKeyword(val){
        const isPresent = this.keywordsList.findIndex(elem => elem.id == val.id)
        if(isPresent == -1 ) this.keywordsList.push(val)
      },
      removeKeyword(keyword){
        this.keywordsList = this.keywordsList.filter(elem => elem.id != keyword)
      },
      label(){
        this.$emit('label', {
          "channelId": this.item.originalChannelId,
          "type": "label",
          "label": this.keywordsList.map(elem => elem.id),
          "channelName": this.item.originalChannelName,
          "thumbUrl": this.item.originalThumbUrl
        })
      },
      getSuggestionValue(suggestion) {
        this.addKeyword(suggestion.item)
        let { item } = suggestion;
        return item.title
      },
      filterResults(a){
        if(a.length){
          this.sortedLabels.forEach(elem => {
            elem.data = this.labels.filter(item => {
              console.log(item.title, elem.name, item.category ==  elem.name, item.title.toLowerCase(), a, item.title.toLowerCase().indexOf(a) != -1, )
              if(item.category ==  elem.name && item.title.toLowerCase().indexOf(a) != -1) return item
            })
          })
          this.sortedLabels = this.sortedLabels.filter(elem => elem.data.length)
        } else this.sortedLabels = JSON.parse(JSON.stringify(this.sortedLabelsBase))
      },
      onSelected(item) {
        this.selected = item.item;
      },
    },
    computed: {
      ...mapGetters({
        user_meta: 'general/user_meta'
      })
    },
    mounted() {
      let categories = this.labels.map(elem => elem.category)
      categories = [...new Set(categories)]
      categories.forEach(element => {
        this.$set(this.sectionConfigs, element, {
          label: element,
          onSelected: selected => {
            this.selected = selected.item.title;
          }
        })
        this.sortedLabels.push({
          name: element,
          data: this.labels.filter(elem => elem.category == element)
        })
      })
      this.sortedLabelsBase = JSON.parse(JSON.stringify(this.sortedLabels))
      if(this.preSelected){
        const dataList = this.sortedLabelsBase.map(elem => elem.data)
        const modifiedList = []
        dataList.forEach(elem => modifiedList.push(...elem))
        
        this.keywordsList = modifiedList.filter(elem => elem.id == this.preSelected)
      }
    },
  }
</script>

<style lang="scss">
#autosuggest{
  position: relative;
  &__input {
    outline: none;
    position: relative;
    display: block;
    padding: 5px 16px;
    width: 100%;
    font-family: 'IBM Plex Mono Demi', 'mono';
    font-size: 14px;
    margin-top: 24px;
    color: #fff;
    border-bottom: 1px solid #fff;
    &::placeholder{
      color: #fff;
    }
  }
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: inline;

  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  background: #3D3D3D;
  padding: 0px;
  max-height: 300px;
  overflow-y: scroll;
}
.my-suggestion-description{
  font-family: 'Ilisarniq', 'sans-serif';
  font-size: 14px;
  color: #787878;

}
.my-suggestion-wrap{
  border-bottom: 1px dashed #4E4E4E;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 4px;
  padding-top: 4px;
}
.autosuggest__results ul {
  list-style: none;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  font-family: 'Ilisarniq', 'sans-serif';
  font-size: 16px;
  color: #F8F8F8;
}

#autosuggest ul:nth-child(1) > .autosuggest__results-before {
  border-top: none;
}

.autosuggest__results .autosuggest__results-before {
  color: #DCD3FF;
  font-family: 'IBM Plex Mono', 'mono';
  font-size: 14px;
  margin-left: 0;
  padding-bottom: 4px;
  margin-left: 16px;
  margin-right: 16px;
  border-bottom: 1px solid #4E4E4E;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: #484848;
}
</style>